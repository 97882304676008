import "./topbar.scss";
import { PhoneIphone, Mail, LocationOnSharp } from "@material-ui/icons";


export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            Damola.
          </a>
          <div className="itemContainer">
            <PhoneIphone className="icon" />
            <span>+44 7901837849</span>
          </div>
          <div className="itemContainer">
            <Mail className="icon" />
            <span>badmusadedamola@gmail.com</span>
          </div>
          <div className="itemContainer">
            <LocationOnSharp className="icon" />
            <span>United Kingdom</span>
          </div>
          {/* <div className="itemContainer">
            <GitHub className="icon" />
            <a href="https://github.com/AdedamolaB?tab=repositories" rel="noopener"> <span> Github </span></a>
          </div> */}
         
          
        </div>
        <div className="right">
          <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}