export const featuredPortfolio = [
    {
      id: 1,
      // title: "Social Media App",
      img:
        "assets/folio/folio1.png",
    },
    {
      id: 2,
      // title: "Rampa UI Design",
      img:
        "assets/folio/folio2.png",
    },
    {
      id: 3,
      // title: "E-commerce Web Design",
      img:
        "assets/folio/folio3.webp",
    },
    {
      id: 4,
      // title: "Relax Mobile App",
      img:
        "assets/folio/folio4.webp",
    },
    {
      id: 5,
      // title: "Hero Web Design",
      img:
        "assets/folio/folio5.png",
    },
    {
      id: 6,
      // title: "Banking App",
      img:
        "assets/folio/folio6.png",
    },
  ];
  
  export const webPortfolio = [
    {
      id: 1,
      // title: "Web Social Media App",
      img: "assets/folio/folio7.webp",
    },
    {
      id: 2,
      // title: "Web Rampa UI Design",
      img: "assets/folio/folio8.png",
    },
    {
      id: 3,
      // title: "Web E-commerce Design",
      img: "assets/folio/folio9.png",
    },
    {
      id: 4,
      // title: "Web Relax App",
      img: "assets/folio/folio10.webp",
    },
    {
      id: 5,
      // title: "Web Design",
      img: "assets/folio/folio11.jpg",
    },
    {
      id: 6,
      // title: "Web Banking App",
      img: "assets/folio/folio12.jpg",
    },
  ];
  
  export const mobilePortfolio = [
    {
      id: 1,
      title: "Mobile Social Media App",
      img:
        "https://cdn.dribbble.com/users/2597268/screenshots/15468893/media/44313553d73ba41580f1df69749bba28.jpg?compress=1&resize=1200x900",
    },
    {
      id: 2,
      title: "Mobile Rampa UI Design",
      img:
        "https://cdn.dribbble.com/users/5920881/screenshots/15463142/media/b5a460223798bd85d835710806e2f3dd.png?compress=1&resize=1200x900",
    },
    {
      id: 3,
      title: "Mobile E-commerce Design",
      img:
        "https://cdn.dribbble.com/users/1998175/screenshots/15459384/media/48ac2b43ebe81ba0866afea1383cc939.png?compress=1&resize=1200x900",
    },
    {
      id: 4,
      title: "Mobile Relax App",
      img:
        "https://cdn.dribbble.com/users/2083704/screenshots/15468619/media/cd958306c7a772449e1ac23bd65ce506.png?compress=1&resize=1200x900",
    },
    {
      id: 5,
      title: "Mobile Hero Design",
      img:
        "https://cdn.dribbble.com/users/26642/screenshots/15462545/media/1a202ef5c8338b6f78634e6edc1ba350.png?compress=1&resize=1200x900",
    },
    {
      id: 6,
      title: "Mobile Banking App",
      img:
        "https://cdn.dribbble.com/users/1387827/screenshots/15466426/media/deb2dca6762cd3610321c98bfccb0b72.png?compress=1&resize=1200x900",
    },
  ];
  
  export const designPortfolio = [
    {
      id: 1,
      title: "Design Social Media App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 2,
      title: "Design Rampa UI Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 3,
      title: "Design E-commerce Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 4,
      title: "Design Relax Mobile App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 5,
      title: "Design Keser Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 6,
      title: "Design Banking App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
  ];
  
  export const contentPortfolio = [
    {
      id: 1,
      title: "Content Social Media App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 2,
      title: "Content Rampa UI Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 3,
      title: "Content E-commerce Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 4,
      title: "Content Relax Mobile App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 5,
      title: "Content Keser Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 6,
      title: "Content Banking App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
  ];